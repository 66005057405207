.notification-banner {
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 16px 20px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(8px);
  border: 1px solid #2c389e;
}

.notification-banner .banner-icon {
  flex-shrink: 0;
  margin-right: 16px;
  margin-top: 2px;
  color: rgba(255, 255, 255, 0.6);  /* This sets a lighter grey for the icon by default */
}

.notification-banner .banner-content {
  flex-grow: 1;
}

.notification-banner .banner-title {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 4px 0;
}

.notification-banner .banner-message {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

.notification-banner a { /* in case the caller passes text with an inline link */
  font-size: 16px;
}

/* Theme variations */
.notification-banner.warning {
  background: rgba(255, 166, 0, 0.13);
  border-color: rgba(255, 166, 0, 0.25);
}

.notification-banner.error {
  background: rgba(255, 59, 48, 0.16);
  border-color: rgba(255, 59, 48, 0.3);
}

/* Animation */
.notification-banner {
  transition: all 0.2s ease;
}

/*
.notification-banner:hover {
  border-color: rgba(255, 255, 255, 0.2);
}
*/