@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

.account-main {
    padding: 74px 0;
}

.banner-heading {
    max-width: 753px;
    margin: 0 auto 45px;
    text-align: center;
}

.account-container {
    height: 100%;
    border-radius: 30px;
    border: 1px solid #2C389E;
    background: #000212;
}

.account-container .account_heading {
    padding: 22px 40px;
    border-bottom: 1px solid #2C389E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.account-container > div:not(:last-child) {
    border-bottom: 1px solid #2C389E;
    padding: 22px 40px;
}

.account-container .account_heading h3 {
    color: #146EF5;
    text-align: left;
    font-family: 'Samsung Sharp Sans Bold';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.account-container .account-content {
    padding: 30px 40px;
}

.account-container .account-content .guide-steps > div > *:last-child {
    margin-bottom: 0;
}

.account-container .account-content .step-box h4,
.account-container .account-content .step-box h5 {
    color: #fff;
    text-align: left;
    font-family: 'Samsung Sharp Sans Bold';
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.account-container .account-content .step-box h4 {
    font-size: 20px;
    margin: 0 0 15px;
}

.account-container .account-content .step-box h5 {
    font-size: 18px;
    margin: 0 0 10px;
}

.account-container .account-content .step-box h5 a {
    font-size: inherit !important;
}

.account-container .account-content .step-box p {
    color: #CCC;
    font-family: "DM Sans", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 20px;
}

.account-container .account-content .step-box ul {
    padding-left: 20px;
    margin-bottom: 30px;
}

.account-container .account-content .step-box li {
    font-family: "DM Sans", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    list-style: disc;
    color: #ccc;
}

.account-container .account-content .step-box ol li {
    list-style: decimal;
}

.account-container .account-content .step-box ol li ul li {
    list-style: disc;
}

.account-container .account-content .step-box li:not(:last-child) {
    margin-bottom: 4px;
}

.account-container .account-content .step-box li a {
    font-size: inherit;
    color: #146EF5;
}

.account-container .account-content .guide-steps > div:first-child div#onboarding,
.account-container .account-content .guide-steps > div:not(:first-child) {
    padding-top: 60px;
}

.account-container .account-content .step-box p iframe {
    width: 100%;
    max-width: 560px;
}

.account-container .account-content .step-tab-links {
    position: sticky;
    top: 30px;
    padding-left: 15px;
}

.account-container .account-content .step-tab-links .steps-line {
    width: 2px;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    background: #041d5b;
}

.account-container .account-content .step-tab-links .step-active-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.account-container .account-content .step-tab-links ul li button {
    padding: 7px 0;
    color: #146EF5;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    display: block;
    position: relative;
    background: none;
    outline: none;
    box-shadow: none;
    border: none;
    text-align: left;
}

.account-container .account-content .step-tab-links ul li.active ~ li button {
    color: #ccc;
}

.account-container .account-content .step-tab-links ul li.active button:after {
    content: '';
    position: absolute;
    top: 0;
    left: -15px;
    width: 2px;
    height: 100%;
    background: #146EF5;
}

.guide-steps .step-box img {
    width: 1000px;
    max-width: 100%;
    border-radius: 20px;
}

@media only screen and (max-width: 1199px) {
    .account-container {
        border-radius: 10px;
    }

    .account-container > div:not(:last-child) {
        padding: 18px 20px;
    }

    .account-container .account_heading h3 {
        font-size: 18px;
    }

    .account-container .account-content {
        padding: 25px 20px;
    }

    .account-main {
        padding: 40px 0;
    }
}

@media only screen and (max-width: 991px) {

    .banner-heading {
        margin-bottom: 25px;
    }

    .account-container .account-content .guide-steps > div:not(:first-child) {
        padding-top: 60px;
    }

    .account-container .account-content .guide-steps > div:first-child div#onboarding,
    .account-container .account-content .guide-steps > div:not(:first-child) {
        padding-top: 30px;
    }

    .account-container .account-content .row {
        flex-direction: column-reverse;
    }

    .account-container .account-content .step-tab-links {
        margin-bottom: 30px;
        background: rgb(255 255 255 / 5%);
    }
}

@media only screen and (max-width: 767px) {
    .account-container > div:not(:last-child),
    .account-container .account-content {
        padding: 15px;
    }

    .account-container .account_heading h3 {
        font-size: 17px;
    }

    .account-container .account-content .row > div:not(:last-child) .account-box {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .account-container .account-content .step-box ol {
        padding-left: 20px;
    }
}