header.header {
    border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.navText{
    text-decoration: none;
    color: aliceblue;
}

.navTextActive{
    color: blue;
}

.navbar-brand, .header-icon {
    width: fit-content;
    margin-right: 0;
    padding: 0 !important;
}

.header-container {
    padding: 20px 0;
}

.header-container .header-icons-list {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 10px;
}

.header-container .header-icon button {
    background: none;
    width: 40px;
    height: 40px;
    border: 1px solid #146EF5;
    border-radius: 100%;
    display: inline-block;
    padding: 0px !important;
    
}

.header-container .header-icon button svg {
    flex: 0 0 20px;
}

.header-container .header-icon button span.toggle-line {
    width: 22px;
    height: 2px;
    display: block;
    background: #146EF5;
    margin-left: auto;
    margin-right: auto;
    transition-timing-function: cubic-bezier(0.16,1,0.3,1);
    transition-duration: 500ms;
    transition-property: opacity, transform;
}

.header-container .header-icon:not(.collapsed) button span.toggle-line:nth-child(1) {
    transform: rotate(-45deg) translateY(-3px);
}

.header-container .header-icon:not(.collapsed) button span.toggle-line:nth-child(2) {
    opacity: 0;
    transform: scale(0);
}

.header-container .header-icon:not(.collapsed) button span.toggle-line:nth-child(3) {
    transform: rotate(45deg) translateY(3px);
}

.header-container .header-icon button span.toggle-line:nth-child(1) {
    transform-origin: bottom right;
}

.header-container .header-icon button span.toggle-line:nth-child(3) {
    transform-origin: top right;
}



.navbar-collapse .navbar-nav .nav-link {
    display: block;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: 'Samsung Sharp Sans Medium';
    color: #fff;
}

.nav-buttons {
    display: flex;
    align-items: center;
}

.navbar-collapse .navbar-nav .nav-link:hover {
    color: #146EF5 !important;
}

.account-detail a:last-child {
    border: 0px;
    padding-bottom: 0px;
}
.account-detail a {
    color: #fff;
    border-bottom: 1px solid #d9d9d9;
    padding: 13px 0px;
}
.close-icon svg path {
    fill: #146EF5 !important;
}
.close-icon svg {
    width: 20px;
    height: 30px;
}
.close-icon {
}
.close-icon {
    background: none;
    width: 40px;
    height: 40px;
    border: 1px solid #146EF5 !important;
    border-radius: 100%;
    display: flex;
    padding: 0px !important;
    align-items: center;
    justify-content: center;
}
.account-detail {
    box-shadow: 0px 10px 80px rgba(0,0,0,0.25);
    border-radius: 10px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.7);
    background: linear-gradient(180deg, #6CA7FF 1.92%, #146EF5 119.23%);
    width: 100%;
    top: 65px;
    position: absolute;
    right: 0;
    transform-origin: top right;
    transition-timing-function: cubic-bezier(0.16,1,0.3,1);
    transition-duration: 500ms;
    transition-property: opacity, transform;
    height: auto !important;
    width: 100%;
    z-index: 9;
}
@media (max-width: 1399px) { 
    .nav-buttons .link-btn {
        font-size: 17px;
        padding: 10px 25px;
    }
}
@media (max-width: 1199.98px) { 
    .nav-buttons .link-btn {
        font-size: 15px;
        padding: 10px 16px;
    }
}

@media only screen and (max-width: 991px) {
    .navbar-collapse:not(.show) {
        visibility: hidden;
        opacity: 0;
        transform: scale(0.9) translateY(-24px);
    }

    .navbar-collapse {
        box-shadow: 0px 10px 80px rgba(0,0,0,0.25);
        border-radius: 10px;
        padding: 20px;
        background: rgba(255, 255, 255, 0.7);
        background: linear-gradient(180deg, #6CA7FF 1.92%, #146EF5 119.23%);
        width: 100%;
        top: 65px;
        position: absolute;
        right: 0;
        transform-origin: top right;
        transition-timing-function: cubic-bezier(0.16,1,0.3,1);
        transition-duration: 500ms;
        transition-property: opacity, transform;
        height: auto !important;
        width: 100%;
        z-index: 9;
    }

    .navbar-collapse .navbar-nav .nav-link {
        padding: 12px 0;
        border-bottom: 1px solid #ccc;
    }
    
    .navbar-collapse .navbar-nav .nav-link:last-child {
        border-bottom: none;
    }

    .nav-buttons {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .header-container .header-icons-list {
        display: none;
    }

    .navbar-collapse {
        justify-content: center;
    }

    .navbar-collapse .navbar-nav .nav-link {
        padding: 0;
        margin-inline: 10px;
    }

    .navbar-collapse .navbar-nav .nav-link.active {
        color: #146EF5;
    }
}

@media only screen and (min-width: 1200px) {

    .navbar-collapse .navbar-nav .nav-link {
        font-size: 18px;
        margin-inline: 15px;
    }
}