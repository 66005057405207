.main-chat .chatbot {
    height: calc(75vh + 30px);
}
.main-chat .chatbot {
    max-width: 100%;
}
.main-chat {
    height: calc(100vh - 167px);
    padding: 20px 0px;
}

.message-list .timing {
    font-size: 13px;
    margin-right: 8px;
    padding-right: 10px;
    color: #7c88a7;
}
.message.user {
    align-items: center;
    padding: 0;
}
.thunbs {
    padding-right: 15px;
    display: flex;
    align-items: center;
    gap: 4px;
}
.timing.left-msg {
    padding-left: 10px;
    margin-left: 8px;
}
.where-did {
    display: flex;
    align-items: center;
    max-width: 400px;
    margin-left: auto;
    justify-content: space-between;
}
.where-did a {
    font-size: 15px;
    color: #146ef5;
}
.mesaage-box {
    margin-bottom: 0px;
}
.user.mesaage-box p {
    background: #ffffff21;
}
.message {
    max-width: 85%;
}
.message p {
    max-width: 100%;
}
.message.bot {
    padding: 0;
}
.message.bot p {
    background: #060f26;
    padding: 20px;
}
.ai-input i {
    font-size: 29px;
}
.ai-input {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
}
.ai-input .input-container {
    margin: 0px;
    position: relative;
}

.ai-input .input-container .loader-container {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust position as needed */
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

.main-chat .generate-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.main-chat .generate-button .bi-stars {
    color: white;
    font-size: 35px;
}

.main-chat .generate-button.greyed-out {
    cursor: default;
}

.main-chat .generate-button.greyed-out i {
    color: #555;
}

.main-chat .generate-container {
    position: relative;
}

.main-chat .generate-menu {
    position: absolute;
    top: -110px; /* Adjust this value as needed */
    left: 0;
    background-color: rgb(0,15,60);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    width: auto;
    z-index: 1000;
}
  
.main-chat .generate-menu-item {
    background: none;
    border: none;
    padding: 10px 5px;
    cursor: pointer;
    text-align: left;
    color: #FFF;
    border-radius: 10px;
    font-size: 14px;
}

.main-chat .generate-menu-item.greyed-out {
    cursor: default;
    color: #555;
}
  
.main-chat .generate-menu-item:not(.greyed-out):hover {
    background-color: #888;
}

.main-chat .input-container textarea {
    width: 100%;
    box-sizing: border-box;
    resize: none; /* Prevent manual resizing */
    overflow: auto; /* Hide scrollbars */
    max-height: 145px;
}

.main-chat .chat-send-button {
    padding: 10px 10px;
    border-radius: 50%;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}