/* .custom-calendar-modal .modal-dialog {
  max-width: 960px;
} */

.calendar table {
  width: 100%;
}

/* .calendar {
  height: 613px;
  overflow-y: auto;
} */

.calenderHeader {
  padding: 30px 50px !important;
  margin-bottom: 0 !important;
  /* border-bottom: 3px solid #13317b; */
  align-items: center;
}

.calendar tr:last-child td,
.calendar tr:last-child th {
  border-bottom: none;
  font-weight: 500;
}

.calendar td .main-td-inner {
  min-height: 134px;
  min-width: 100px;
  width: 100%;
  height: 100%;
}

.calendar td,
.calendar th {
  border-color: #13317b !important;
  border: 1px solid;
}

.calendar .calendar-day.past-date {
  background: rgb(255 255 255 / 10%);
  cursor: not-allowed;
}

.calendar .date-text {
  color: #fff;
  padding: 10px;
}

.calendar .calendar-day.past-date .date-text {
  opacity: 0.3;
}

.status-data span,
.status-data p {
  font-size: 11px;
  line-height: normal;
}

.status-data {
  padding: 5px;
}

.status-data-grid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.main-calendar-card {
  border: 1px solid #0d6efd;
  border-radius: 15px;
}
.main-calendar-card-data {
  min-height: 265px;
  max-height: 265px;
  overflow-y: auto;
  color: #fff;
}
.main-calendar-card-data-child {
  display: grid;
  grid-template-columns: 50px auto 20px;
  font-size: 13px;
  text-align: center;
  border-top: 1px solid #0d6efd;
  min-height: 88px;
}
.main-calendar-card-data-child svg {
  color: #0d6efd;
}
.main-calendar-data-status {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}
button.btn.btn-primary.form-control:hover,
.bg-primary.form-control:hover {
  background-color: #0d6efd !important;
}
.tab-btn-stage {
  outline: none !important;
  box-shadow: none !important;
}
.tab-btn-stage:hover,

.tab-btn-stage.btn-unselected {
  background-color: #000718 !important;
  outline: none !important;
  box-shadow: none !important;
}
.calendar-month-schedule{
  display: grid;
  grid-template-columns: 50px auto 90px auto auto;
  gap: 50px;
} 
.calendar-month-table-data{
  max-height: 346px;
  overflow-y: auto;
  padding-right: 10px;
}
.calendar-month-table-data::-webkit-scrollbar {
  width: 3px;
}
@media screen and (max-width:1200px){
  .calendar-month-schedule {
    gap: 15px;
    grid-template-columns: auto auto auto auto auto;
}
}
@media screen and (max-width: 991px) {
  .custom-calendar-modal .modal-dialog {
    max-width: 700px;
    padding: 10px;
  }

  .calendar td > div,
  .calendar td {
    min-height: 70px;
  }

  /* .calendar {
    height: 465px;
  } */

  .calendar tr td,
  .calendar tr th {
    font-size: 15px;
  }

  .calenderHeader h3 {
    font-size: 18px !important;
  }

  .calenderHeader .btn {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 575px) {
  .custom-calendar-modal .modal-dialog {
    max-width: 700px;
  }

  .calendar td > div,
  .calendar td {
    min-height: 40px;
  }

  .calendar tr td,
  .calendar tr th {
    font-size: 12px;
  }

  .calenderHeader h3 {
    font-size: 14px !important;
  }

  .calenderHeader .btn {
    font-size: 12px !important;
    padding: 5px;
  }

  .calenderHeader {
    padding: 15px !important;
  }

  .calendar {
    height: 100%;
    /* max-height: 280px; */
  }
  .calendar-month-schedule {
    font-size: 12px;
}
}
