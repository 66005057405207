#integrate_form1 .custom_checkbox { /* this is the entire tile for each listed property */
  border-radius: 20px;
  border: 2px solid #146ef5;
  background: #000426 !important;
  padding: 18px 22px;
  padding-left: 67px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.3s ease;
  margin-bottom: 20px; /* increased from 3px to 20px */
}

#integrate_form1 .custom_checkbox.additional-option {
  border-color: #77A; /* greyish blue color */
}

#integrate_form1 .custom_checkbox .form-check-input { /* this is the checkbox */
  border: 2px solid #146ef5;
  background-color: #000426;
  width: 28px;
  height: 28px;
  margin-left: -40px;
  display: inline-block;
  vertical-align: middle;
}

#integrate_form1 .custom_checkbox .form-check-input:checked { /* this is the checked checkbox */
  background: #146ef5;
  border: 4px solid #000420;
  box-shadow: 0 0 0 2px #146ef5 !important;
  width: 28px;
  height: 28px;
}

#integrate_form1 .custom_checkbox label { /* this is the text next to the checkbox */
  color: #fff;
  text-align: left;
  font-size: 18px;
  padding-left: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 !important;
  pointer-events: none;
}

#integrate_form1 .custom_checkbox input[type="radio"] {
  pointer-events: none;
}

#integrate_form1 .custom_checkbox:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 1);
}
