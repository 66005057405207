.conv-showcase-container .message.host {
    align-items: center;
    padding: 0;
}
.conv-showcase-container .message.guest {
    padding: 0;
}
.conv-showcase-container .message.guest p {
    background: #060f26;
    padding: 20px;
    color: #fff;
}
.conv-showcase-container .message.guest {
    padding: 0;
}

/* ========= */
.conv-showcase-container .box-header {
    padding: 10px 0px 25px 0px;
    max-width: 80%;
    margin: 0 auto;
}

.conv-showcase-container .box-header h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
}
.conv-showcase-container .box-header p {
    font-size: 16px;
    font-weight: 500;
    color: #afafaf;
}
.conv-showcase-container .chat-box {
    padding: 40px 30px;
    border: 1px solid #146ef5;
    border-radius: 20px;
    position: relative;
    flex: 0 0 69%;
    height: 80vh;
    overflow-y: scroll;
    background: #000212;
}

.conv-showcase-container .main-chat-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 0 auto 30px auto;
    width: 90%;
}

.conv-showcase-container .main-chat-box .arwow {
    cursor: pointer;
}

.conv-showcase-container .main-chat-box .arwow.next i, .conv-showcase-container .main-chat-box .arwow.prev i {
    font-size: 150px;
    color: #146ef5;
}

.conv-showcase-container .chat-box::-webkit-scrollbar {
    display: none; 
}

.conv-showcase-container .main-chat-box .message.host p {
    background: #2e2e2e;
}

.conv-showcase-container .chat-box span {
    font-size: 13px;
    display: block;
    color: #a5b1c5;
    font-weight: 500;
}

.conv-showcase-container .arwow.prev.Show-Icon {
    opacity: 1;
}

.conv-showcase-container .translate-button-container {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .conv-showcase-container .translate-button {
    color: #146ef5;
    cursor: pointer;
    display: inline-block;
    margin: 0 auto;
    font-size: 18px;
  }
  
  .conv-showcase-container .translate-button:hover {
    text-decoration: none;
  }
.conv-showcase-container .arwow.prev , .conv-showcase-container .main-chat-box .Show-Icon111{
    opacity: 0;
}
.conv-showcase-container .message.guest p, .conv-showcase-container .message.host  p {
    font-size: 16px;
    line-height: 22px;
    padding: 13px;
    margin: 0px;
}
.conv-showcase-container .message.guest {
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    position: relative;
    animation: msgreceive .3s ease-in forwards;
}
.conv-showcase-container .message img {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border-radius: 100%;
    object-fit: cover;
}

.conv-showcase-container .message.host {
    align-self: flex-end;
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    animation: msgsent .3s ease-in forwards;
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
}
@media (max-width: 1599px) { 
    .conv-showcase-container .main-chat-box .arwow.next i, .conv-showcase-container .main-chat-box .arwow.prev i {
        font-size: 120px;
    }
    .conv-showcase-container .chat-box {
        flex: 0 0 75%;
    }
    .conv-showcase-container .main-chat-box {
        gap: 10px;
    }
}
@media (max-width: 1399px) { 
    .conv-showcase-container .main-chat-box .arwow.next i, .conv-showcase-container .main-chat-box .arwow.prev i {
        font-size: 100px;
    }
    .conv-showcase-container .chat-box {
        flex: 0 0 75%;
    }
}
@media (max-width: 991px) { 
    .conv-showcase-container .main-chat-box .arwow.next i, .conv-showcase-container .main-chat-box .arwow.prev i {
        font-size: 90px;
    }
    .conv-showcase-container .box-header h2 {
        font-size: 30px;
    }
    .conv-showcase-container .chat-box {
        padding: 20px 10px;
    }
    .conv-showcase-container .box-header p {
        font-size: 15px;
        line-height: 25px;
    }
}
@media (max-width: 575px) { 
    .conv-showcase-container .box-header h2 {
        font-size: 25px;
        margin-bottom: 10px;
    }
    .conv-showcase-container .main-chat-box {
        gap: 0px;
    }
    .conv-showcase-container .main-chat-box .arwow.next i, .conv-showcase-container .main-chat-box .arwow.prev i {
        font-size: 60px;
    }
    .conv-showcase-container .chat-box {
        flex: 0 0 85%;
    }
    .conv-showcase-container .message.guest p, .conv-showcase-container .message.host  p {
        font-size: 15px;
        line-height: 25px;
        padding: 13px;
    }
}