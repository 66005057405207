@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

.account-main {
    padding: 74px 0;
}

.account-main form > div:not(:last-child) {
    margin-bottom: 15px;
}



 .account-navbar .col {
    display: flex;
    justify-content: center;
    align-items: center;
}

 .account-navbar .nav-option {
    background: none;
    border: none;
    color: white;
    font-size: 18px; /* Increase the font size */
    font-weight: bold;
    font-family: 'Roboto', sans-serif; /* Modern font */
    cursor: pointer;
}

 .account-navbar .nav-option:hover {
    color: #ccc;
}

 .account-navbar .nav-option.selected {
    box-shadow: 0px -0px 10px 3px rgba(0, 0, 255, 1);
}






 .account-content {
    padding: 30px 40px;
}

 .account-content h3 {
    color: white;
    text-align: center;
    margin: 10px 0;
}

 .account-content h4 {
    color: white;
    margin-top: 20px;
    margin-bottom: 38px;
    font-size: 20px;
}

 .account-content h5 {
    color: white;
    text-align: center;
    margin-top: 20px;
}

 .account-content h6 {
    color: white;
}

 .account-content .disabled-input {
    color: grey;
  }

 .account-content .warning-text {
    color: rgb(255, 165, 0);
}

 .account-content .grey-text {
    color: #AAA;
}

 .account-content .clickable-text {
    color: rgb(0, 121, 247);
    cursor: pointer;
}

 .account-content p {
    color: white;
    margin-bottom: 30px;
    font-size: 16px;
}

 .account-content .confirmation_code_input input {
    border: 1px solid white;
    color: white;
    width: 100px;
    padding: 2px 10px;
    margin-right: 10px;
}

 .account-content .recipient {
    border: 1px solid #2C389E;
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 35px;
    box-shadow: 2px -2px 15px 1px rgba(255, 255, 255, 0.25);
}

 .account-content .in-section-divider {
    border-color: white;
    border-style: solid;
    border-width: 2px;
}

 .account-content link {
    margin-top:'10px';
    text-align:'center';
}

 .account-content form label {
    color: #FFF;
    font-family: 'Samsung Sharp Sans Medium';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

 .account-content form label:not(.form-floating label) {
    margin-bottom: 5px;
}

 .account-content form input {
    border-radius: 80px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: #000212;
}

 .checkbox-container {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}

 .account-content .checkbox-container .form-check-input {
    width: 20px;
    height: 20px;
    border: 3px solid white;
    margin-right: 10px; /* margin between checkbox and the text label */
    border-radius: 50%;
}

 .account-content .checkbox-container label {
    margin-top: 2px; /* Align the text label with the checkbox */
    font-size: 16px;
}

 .account-content .slack-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#slack-button {
    align-items:center;
    color:#000;
    background-color:#fff;
    border:1px solid #ddd;
    border-radius:4px;
    display:inline-flex;
    font-family:Lato,sans-serif;
    font-size:16px;
    font-weight:600;
    height:48px;
    justify-content:center;
    text-decoration:none;
    width:236px;
}

#slack-button svg {
    height:20px;
    width:20px;
    margin-right:12px;
}
.multiselector .search-option {
   
    left: 14px;
    width: 95%;
    top:41px;
    background-color: #0a1a44;
   }
   .multiselector .search-multi{
    background-color: #0a1a44;
    border:1px solid #146EF5;
    border-radius: 50px;
   }
   .search-multi input {
    background: transparent !important;
    border: none !important;
}
.multioption {
    overflow-y: scroll;
    height: 150px;
}
input#multi-search {
   
    width: 100%;
    height: 30px;
}
.multiselector.item-select input {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: #0a1a44;
    padding: 9px 15px;
}



.action-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}
.action-heading h3 {
    font-size: 25px;
    color: #fff;
}
.action-select {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;
}
.action-select select {
  border: 1px solid #146ef5;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 16 16%22%3E%3Cpath fill=%22none%22 stroke=%22%23ffffff%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22 stroke-width=%222%22 d=%22m2 5 6 6 6-6%22/%3E%3C/svg%3E");
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust position if needed */
}

.action-select select:focus {
  outline: 0;
  box-shadow: none;
}
.search-wrapper.searchWrapper, .multiinputfirst1 input, .search-multi1{
  border: 1px solid #146ef5;
  border-radius: 50px;
  padding: 5px;
  position: relative;
  background: #212529;
}
.search-multi input{
    background-color: transparent;
    border:none;
}
.multiinputfirst input{
    color:#fff;
    font-size:14px;
    padding-left:10px;
    padding-right:10px;
    width:100%;
}
.multiinputfirst, .search-multi {
  position: relative;
}
.pro-icon1 {
  position: absolute;
  top: 50%;
  color: #fff;
  right: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  padding-left:10px;
    padding-right:10px;
}
.action-select .item-select {
    width: 23%;
    
}
.multiselector.item-select{
    position: relative;
    width:23%;

}
.account-main .search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    color: #fff;
}
.account-main .search-option {
    position: absolute;
    top: 33px;
    left: 0;
    background: #212529;
    padding: 10px 10px;
    border-radius: 20px;
}

.account-main .search-option ul li {
    color: #fff;
    font-size: 14px;
    margin: 5px 0px;
    border: 1px solid #146EF5;
    border-radius: 10px;
    padding: 7px;
    display: flex;
    align-items: center;
    gap: 10px;
}


input#multi-search {
    padding-left: 30px;
    color: #fff;
    font-size: 14px;
}


@media screen and (max-width:991px)
{
    .action-heading h3 {
        font-size: 20px;
        
    }
}
@media screen and (max-width:767px)
{
    .action-heading h3 {
        font-size: 18px;
        
    }
    .action-items {
       flex-wrap:wrap;
       
       }
       .action-select{
        justify-content: start;
        padding-top:20px;
        flex-wrap:wrap;

       }
}
@media screen and (max-width:575px)
{
    .action-select .item-select {
        width: 100%;
    }
    .search-option1, .multiselector1.item-select1 {
        
        width: 100%;
    }
}

@media only screen and (max-width: 1199px) {
     .account-content {
        padding: 25px 20px;
    }

    .account-main {
        padding: 40px 0;
    }
}

@media only screen and (max-width: 991px) {
     .account-content .get-started {
        margin-top: 20px;
    }
}