/* Multi Properties Chat styles */
.multi-properties-chat {
  color: white;
}

.multi-properties-chat h4 {
  margin-bottom: 20px;
}

.multi-properties-chat p {
  font-size: 16px;
}

.multi-properties-chat .beta-label {
  color: orange;
  font-size: 0.8em;
}

.multi-properties-chat .add-new-button {
  margin-top: 20px;
  color: #007bff;
  background: none;
  border: none;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

/* Edit Multi Property styles */
.edit-multi-property {
  width: 100%;
  min-height: 100vh;
  color: white;
}

.edit-multi-property-content {
  margin: 0 auto;
  padding: 40px 80px;
}

.edit-multi-property-content .go-back {
  color: #0d6efd;
  text-decoration: none;
  transition: transform 0.2s ease;
  display: inline-block;
}

.edit-multi-property-content .go-back:hover {
  transform: translateY(-2px);
}

.edit-multi-property .custom-section {
  margin-top: 30px;
}

.edit-multi-property .custom-section h2 {
  margin-bottom: 5px;
  color: white;
}

.edit-multi-property .custom-section p {
  margin-bottom: 10px;
  font-size: 14px;
  opacity: 0.8;
  color: white;
}

.edit-multi-property .custom-section textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border-radius: 4px;
}

.edit-multi-property label {
  color: white;
}

.edit-multi-property .btn-primary {
  display: block;
  margin: 30px auto 0;
}

/* New styles for Edit Multi Property layout */
.name-and-selector-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}

.name-field, .property-selector {
  flex: 1;
}

/* Set max-width for the Name input */
.name-input {
  max-width: 400px;
}

/* Tile styling for multi-property-entry */
.multi-property-entry {
  background-color: #151530;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
}

/* Dropdown button styling within multi-property-entry */
.multi-property-entry .property-dropdown {
  display: inline-flex;
  align-items: center;
  position: static; /* added to anchor dropdown in tile */
}

.multi-property-entry .dropdown-toggle-vertical {
  background: none;
  border: none;
  padding: 0;
  color: white;
}

/* Modify dropdown toggle arrow to display the caret */
.multi-property-entry .dropdown-toggle-vertical::after {
  display: inline-block;
  margin-left: -8px;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}