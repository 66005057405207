.navigation-links {
    border-radius: 30px;
    background: #020D29;
    padding: 50px 0;
    position: sticky;
    top: 30px;
}
.custom-nav-links{
    border-radius: 30px 0px 0px 30px;
}

.navigation-links ul {
    padding: 0;
    list-style: none;
}

.navigation-links ul li:not(:last-child) {
    margin-bottom: 30px;
}

.navigation-links ul li a {
    color: #CDCDCD;
    font-family: 'Samsung Sharp Sans Medium';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
    text-align: left;
    padding: 10px 15px 10px 46px;
}

.navigation-links ul li a.active {
    color: #146EF5;
    font-weight: 700;
    border-left: 6px solid #146EF5;
    padding-left: 40px;
}

@media only screen and (max-width: 1399px) {
    .navigation-links ul li a{
        font-size: 14px;
    }
}

@media only screen and (max-width: 1199px) {
    .navigation-links {
        padding: 30px 0;
        border-radius: 10px;
    }
    .navigation-links ul li a{
        font-size: 14px;
        padding-left: 25px;
    }
    .navigation-links ul li a.active{
        padding-left: 25px;
    }
}

@media only screen and (max-width: 991px) {
    .navigation-links {
        margin-bottom: 30px;
    }

    .navigation-links ul li:not(:last-child) {
        margin-bottom: 20px;
    }

    .navigation-links ul li a {
        padding-left: 24px;
        font-size: 18px;
    }

    .navigation-links ul li a.active {
        border-left-width: 4px;
        padding-left: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .navigation-links {
        margin-bottom: 20px;
    }

    .navigation-links ul li:not(:last-child) {
        margin-bottom: 15px;
    }

    .navigation-links ul li a {
        font-size: 16px;
    }
}