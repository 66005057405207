.inbox-nav-bar button {
    background: transparent;
    color: #fff;
    border: 0px;
    padding: 5px 20px;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
}
.inbox-nav-bar {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
}
.inbox-nav-bar button.nav-active {
    border-top: 3px solid #2A87E8;
}
.inbox-nav-bar a {
    font-size: 16px;
}

.nav-buttons-container {
    display: flex;
    align-items: center;
}

.timezone-notification {
    font-size: 16px;
    color: rgb(255, 165, 0);
    padding: 10px 0;
    margin: 0;
}

.nav-icon {
    font-size: 20px;
    display: none;
}

@media (max-width: 768px) {
    .inbox-nav-bar {
        padding: 0;
    }

    .inbox-nav-bar button {
        flex: 1;
        justify-content: center;
        padding: 10px 0;
    }

    .nav-buttons-container {
        width: 100%;
    }

    .nav-icon {
        display: block;
    }
    
    .nav-label {
        display: none;
    }
}