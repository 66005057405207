@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

.pricing {
  padding: 70px 0;
}

.pricing .pricing-plans-descriptions-lg {
  margin-bottom: 50px;
  padding: 50px;
  border: 1px solid #146EF5;
  border-radius: 30px;
  background: #000212;
}

.pricing .pricing-plans-descriptions-md-sm {
  display: none;
  margin-bottom: 30px;
  padding: 0 15px;
}

.pricing .pricing-plans-descriptions-lg ul li, .pricing .pricing-plans-descriptions-md-sm ul li, .pricing-plans-descriptions-md-sm p, .pricing-plans-descriptions-lg p {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #CCC;
  list-style-type: disc; /* Add bullet points to unordered list items */
  margin-bottom: 8px;
}

.pricing p strong {
  color: #CCC;
  font-weight: bold;
  font-family: 'Samsung Sharp Sans Bold';
}

.pricing .pricing-plans-descriptions-lg ul li strong, .pricing .pricing-plans-descriptions-md-sm ul li strong {
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(180deg, #A74DFE 1.92%, #8B5FCF 119.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
}

.pricing .pricing-plans-descriptions-lg ul li, .pricing .pricing-plans-descriptions-md-sm ul li {
  margin-left: 15px;
}

.pricing .pricing-heading {
  margin-bottom: 25px;
  text-align: center;
  max-width: 753px;
  margin: 0 auto 45px;
}

.pricing .all-pricing-information {
  margin-bottom: 50px;
}

.pricing .all-pricing-information h3, .pricing .pricing-plans-descriptions-lg h3 {
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(180deg, #6CA7FF 1.92%, #146EF5 119.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
}

.pricing .pricing-plans-descriptions-lg h3 {
  margin-bottom: 5px;
}

.pricing .all-pricing-information h4 {
  color: #BBB;
  text-align: center;
}

.contact-us-button {
  background: none;
  border: none;
  color: rgb(31, 98, 255);
  cursor: pointer;
}

.pricing .pricing-heading h1, .pricing .pricing-heading h2 {
  color: #fff;
  text-align: center;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 16px;
}

.pricing .pricing-heading p {
  color: #fff;
  text-align: center;
  font-family: "Samsung Sharp Sans medium";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 500;
}

.started {
  position: relative;
  border-radius: 30px;
  border: 1px solid #146ef5;
  box-shadow: 0px 0px 20px 2px #FFF; /* offset-x | offset-y | blur-radius | spread-radius | color */
  max-width: 790px;
  padding: 60px 0;
  text-align: center;
  margin: auto;
}

.started .started-content {
  max-width: 505px;
  margin: auto;
  position: relative;
  z-index: 1;
}

.started .started-content h1, .started .started-content h3 {
  background: var(--Blue, linear-gradient(180deg, #6CA7FF 1.92%, #146EF5 119.23%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #FFF;
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 45px;
  font-style: normal;
  line-height: 50px;
  margin-bottom: 20px;
  width: 100%;
}

.started .started-content h1, .started .started-content h4 {
  background: var(--Blue, linear-gradient(180deg, #6CA7FF 1.92%, #146EF5 119.23%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #FFF;
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 25px;
  font-style: normal;
  line-height: 25px;
  margin-bottom: 20px;
  width: 100%;
}

.started .started-content p {
  color: #DDD;
  font-family: 'Samsung Sharp Sans Medium';
  font-size: 18px;
  font-style: normal;
  margin-bottom: 25px;
  width: 100%;
}

.blog-change {
  color: #DDD;
  font-family: 'Samsung Sharp Sans Medium';
  font-size: 13px !important;
  font-style: normal;
  margin-bottom: 15px !important;
  width: 100%;
}

.started .started-content a {
  font-size: 20px;
  font-family: "Samsung Sharp Sans Bold";
  transition: transform 0.3s ease; /* Smooth transition for the transform */
}

a.explore-link {
  color: #146ef5;
}

@media only screen and (max-width: 1199px) {
  .pricing .pricing-heading h1, .pricing .pricing-heading h2 {
    font-size: 36px;
    line-height: 40px;
  }

  .pricing .pricing-plans-descriptions-lg {
    display: none;
  }

  .pricing .pricing-plans-descriptions-md-sm {
    display: block;
  }

}

@media only screen and (max-width: 991px) {
  .pricing {
    padding: 50px 0;
  }

  .pricing .pricing-heading h1, .pricing .pricing-heading h2 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  .started {
    padding: 40px;
  }

  .started .started-content h1, .started .started-content h3 {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 20px;
  }

  .started .started-content h1, .started .started-content h4 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing .pricing-heading h1, .pricing .pricing-heading h2 {
    font-size: 32px;
    line-height: 38px;
  }

  .pricing .works-pricing-boxes {
    margin-top: 40px;
  }

  .started .started-content h3 {
    font-size: 35px;
    line-height: 40px;
  }

  .started .started-content p {
    font-size: 16px;
  }

  .blog-change { 
    font-size: 14px !important;
  }

  .started .started-content a {
    font-size: 18px;
  }
}
