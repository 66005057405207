@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

footer.footer {
    background: #000107;
}

footer.footer .footer-container {
    padding: 50px 0 30px;
}

.footer .col-md-4 a{
    display: inline-block;
    margin-bottom: 25px !important;
    text-decoration: none;
}

.footer .col-md-4 a img {
    width: auto;
    max-width: 200px;
}

.footer .footer-desc {
    max-width: 277px;
    text-align: left;
}

.footer .col-md-4 p {
    color: #CCC;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.3px;
}

.footer .col-md-3 .links-heading, .footer .links-heading {
    color: #FFF;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 200%;
    letter-spacing: -0.36px;
    margin-bottom: 15px;
    text-align: left;
}

.footer .col-md-3 .links, .footer .links, .footer .links a {
    color: #CCC;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    letter-spacing: -0.3px;
    text-decoration: none;
    margin-bottom: 0;
    text-align: left;
}

.footer .copyright {
    color: rgb(255 255 255 / 50%);
    font-family: 'DM Sans', sans-serif !important;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    letter-spacing: -0.3px;
    text-align: center !important;
    padding: 20px 0;
    border-top: 1px solid rgb(255 255 255 / 10%);
}

.footer .copyright p {
    font-size: inherit !important;
    font-family: inherit !important;
}

.footer .social-link {
    margin-right: 10px;
}

@media only screen and (max-width: 767px) {
    .footer .col-md-3 .links-heading, .footer .links-heading {
        line-height: 26px;
        margin-bottom: 10px;
    }

    .footer .copyright {
        padding: 10px 0;
    }

    footer.footer .footer-container {
        padding: 30px 0;
    }

    .footer .col-md-4 a {
        margin-bottom: 15px !important;
    }

    .footer .footer-desc {
        max-width: 100%;
    }

    footer.footer .footer-container .row > div:not(:last-child) {
        margin-bottom: 20px;
    }

    footer.footer .footer-container .row > div:not(:last-child) > div {
        border-bottom: 1px solid rgb(255 255 255 / 10%);
        padding-bottom: 20px;
    }
}