@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

@font-face {
    font-family: 'Samsung Sharp Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Samsung Sharp Sans Regular'), url('../../../../public/samsungsharpsans.woff') format('woff');
}


@font-face {
    font-family: 'Samsung Sharp Sans Medium';
    font-style: normal;
    font-weight: 500;
    src: local('Samsung Sharp Sans Medium'), url('../../../../public/samsungsharpsans-medium.woff') format('woff');
}


@font-face {
    font-family: 'Samsung Sharp Sans Bold';
    font-style: normal;
    font-weight: 700;
    src: local('Samsung Sharp Sans Bold'), url('../../../../public/samsungsharpsans-bold.woff') format('woff');
}

.features {
    padding: 40px 0 70px;
    position: relative;
    z-index: 1;
}

.features h3 {
    color: #146EF5;
    text-align: center;
    font-family: 'Samsung Sharp Sans Bold';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
}

.features .heading {
    margin-bottom: 50px;
}

.features .heading h2 {
    margin-bottom: 30px;
    font-size: 35px;
}

.features .accordion .accordion-item {
    background: none;
    border: 0;
}

.features .accordion .accordion-item:not(:last-child) {
    margin-bottom: 40px;
}

.features .accordion .accordion-item .accordion-header button {
    background: none !important;
    box-shadow: none !important;
    padding: 0;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-family: 'Samsung Sharp Sans Bold';
    font-size: 22px;
    margin-bottom: 13px;
}

.features .accordion .accordion-item .accordion-header button.collapsed {
    margin-bottom: 0;
}

.features .accordion .accordion-item .accordion-header button::after {
    content: none;
}

.features .accordion .accordion-item.active {
    border-radius: 15px;
    background: linear-gradient(180deg, #181926 0%, rgba(24, 25, 38, 0.30) 100%);
    padding: 35px;
    position: relative;
    overflow: hidden;
    border: none;
}

.features .accordion .accordion-collapse, .features .accordion .accordion-collapse .accordion-body {
    padding: 0;
}

.accordion .accordion-collapse .accordion-body {
    color: #CCC;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.features .accordion .animate-line {
    display: none;
}

.features .accordion .accordion-item.active .animate-line {
    display: block;
    min-width: 32px;
    width: 7%;
    height: 3px;
    background: linear-gradient(270deg, #146EF5 0.95%, rgba(20, 110, 245, 0.00) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 130px;
    animation: line 8s ease forwards;
}

.features .accordion .accordion-item.active .animate-line::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    height: 3px;
    width: 32px;
    border-radius: 130px;
    background: url(../../../public/img/afterline.png) no-repeat right center;
}

.features .features-img {
    padding: 25px;
    border-radius: 30px 30px 0px 0px;
    background: linear-gradient(180deg, #242539 0%, rgba(23, 24, 38, 0.11) 100%);
    height: 278px;
}

.features .features-img img {
    width: 100%;
}

.features .features-content a {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
}

@keyframes line {
    0% {
        width: 7%;
    }
    100% {
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .features .heading h2 {
        font-size: 27px;
        line-height: 38px;
    }

    .features .features-img {
        margin-top: 30px;
        height: auto;
    }

    .features {
        padding: 40px 0;
    }

    .features .heading {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .features .accordion .accordion-item .accordion-header button {
        font-size: 16px;
    }

    .features h3 {
        line-height: 38px;
        font-size: 16px;
    }

    .features .accordion .accordion-item:not(:last-child) {
        margin-bottom: 25px;
    }

    .features .accordion .accordion-item.active {
        padding: 20px;
        border-radius: 10px;
    }

    .features .features-img {
        border-radius: 10px;
        padding: 15px;
    }

    .features {
        padding: 30px 0;
    }

    .features .heading h2 {
        font-size: 20px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 575px) {
    .features .heading {
        margin-bottom: 20px;
    }
}