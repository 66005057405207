@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

body {
  padding: 0;
  margin: 0;
  font-family: "Samsung Sharp Sans Medium";
  background: #000212;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px; /* for horizontal scroll bar */
  display: none; /* this hides the scrollbar */
}

::-webkit-scrollbar-thumb {
  background: #146ef5;
}

::-webkit-scrollbar-track {
  background: #020d29;
}

h1,
h2,
h3,
h4,
ul,
p {
  padding: 0;
  margin: 0;
}

p,
a {
  font-family: "Samsung Sharp Sans Medium";
  font-size: 18px;
}

a {
  transition: 0.4s ease-in-out;
  text-decoration: none;
}
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
}
.rounded-15 {
  border-radius: 15px !important;
}
.bg-black {
  background: #000;
}

.heading h1,
.heading h2 {
  color: #fff;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 13px;
}

.heading.heading-center h2 {
  text-align: center;
}

.heading h1 strong,
.heading h2 strong {
  font-weight: 700;
  color: #146ef5;
}

p {
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  max-width: 100%;
}

input {
  outline: none;
  box-shadow: none !important;
}

textarea {
  resize: none;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #fff;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #fff !important;
}
input::-moz-placeholder {
  opacity: 1;
  outline: none;
}
textarea::-moz-placeholder {
  opacity: 1;
  outline: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #000212 inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}

.form_multisteps input[type="checkbox"] {
  background: #000212;
}
.form-switch .form-check-input {
  background-repeat: no-repeat !important;
}
.bg-none {
  background: transparent !important;
}

.link-btn {
  color: #146ef5;
  font-family: "Samsung Sharp Sans Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 160px;
  border: 1px solid #146ef5;
  display: inline-block;
  padding: 13px 40px;
  background: transparent;
  transition: 0.4s ease-in-out;
}

.filled-btn {
  background: #146ef5;
  color: #fff;
}

.link-btn.filled-btn:hover {
  transition: 0.4s ease-in-out;
  background: transparent;
  color: #146ef5;
}

.link-btn.outline-btn:hover {
  transition: 0.4s ease-in-out;
  background: #146ef5;
  color: #fff;
}

.banner-heading {
  max-width: 753px;
  margin: 0 auto 45px;
  text-align: center;
}

.banner-heading h1,
.banner-heading h2 {
  color: #fff;
  text-align: center;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 16px;
}

.banner-heading p {
  color: #fff;
  text-align: center;
  font-family: "Samsung Sharp Sans Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.banner-heading a {
  margin-top: 35px;
}

.thankerror {
  padding: 190px 0 120px;
  text-align: center;
}

.thankerror p {
  color: #fff;
  text-align: center;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin: 32px 0;
}

.thankerror a {
  width: 100%;
  max-width: 380px;
}

.modal-content {
  border-radius: 30px;
  border: 3px solid #13317b;
  background: #020d29;
  overflow: hidden;
}

.modal-body {
  padding: 30px;
}

.modal-header {
  padding: 30px;
  position: relative;
  border-bottom: 3px solid #13317b;
  justify-content: center !important;
}

.modal-header h5,
.modal-title.h4 {
  color: #fff;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  text-align: center;
  margin: 0;
}

.modal-header .btn-close {
  box-shadow: none !important;
  outline: none;
  filter: brightness(0) invert(1);
  opacity: 1;
  padding: 0;
  position: absolute;
  top: 43px;
  right: 30px;
  z-index: 2;
}

.upgrade-plan-box {
  text-align: center;
  max-width: 585px;
  margin: auto;
  margin-bottom: 40px;
}

.upgrade-plan-box p {
  color: #fff;
  text-align: center;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 50px 0 20px;
}

.addition_des {
  color: #e6e6e6;
  font-family: "Samsung Sharp Sans Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
}

.addition_des_button {
  width: 100%;
  max-width: 300px;
  margin-top: 15px;
  margin-inline: auto;
  padding: 13px 30px;
}

.addition_des_button button {
  width: 100%;
}

.addition_des p {
  margin-top: 30px;
}

.addition_des ol {
  padding-left: 25px;
  margin-top: 6px;
}

.addition_des ol li {
  list-style-type: decimal;
}

.contact-modal .input-group {
  position: relative;
}

.contact-modal .input-group label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  padding: 16px 20px;
}

/* .contact-modal .input-group > .form-control:focus ~ label { */
.contact-modal .input-group > .form-control ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  z-index: 3;
}

/* .contact-modal .input-group > .form-control:focus { */
.contact-modal .input-group > .form-control {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.contact-modal .input-group textarea.form-control {
  border-radius: 20px !important;
  height: 120px !important;
}
.form-design textarea.form-control,
textarea.form-control {
  border-radius: 20px !important;
  height: 120px !important;
  box-shadow: none !important;
}

.bg_theme_btn {
  color: #fff;
  font-family: "Samsung Sharp Sans Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 160px;
  background: #146ef5;
  display: inline-block;
  padding: 13px 40px;
  border: 1px solid #146ef5;
}

.upgrade-plan-box .bg_theme_btn {
  width: 100%;
  max-width: 320px;
}

.upgrade-plan-box .form-design label,
.modal-content label,
.form-design label {
  color: #fff;
  font-family: "Samsung Sharp Sans Medium";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.form-design label span {
  color: #999;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-control,
.css-13cymwt-control,
.form-design .form-control,
.modal-content .form-control {
  border-radius: 80px !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #0a1a44 !important;
  color: #fff;
  font-family: "Samsung Sharp Sans Medium";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding: 16px 20px;
  position: relative;
  margin: 0;
  height: auto;
  max-width: 100%;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  border-radius: 80px !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #0a1a44 !important;
  color: #fff;
  font-family: "Samsung Sharp Sans Medium";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  padding: 5px;
  position: relative;
  margin: 0;
  height: auto;
  max-width: 100%;
}

.form-design .form-control[type="file"]::file-selector-button {
  padding: 8px 10px;
  border: 0px;
  border-radius: 6px;
  background-color: #146ef5 !important;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 5px;
}
.form-design .form-control[type="file"] {
  color: #4d5d87;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-design .custom_checkbox label {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-left: 10px;
  line-height: normal;
}
.form-design .custom_checkbox {
  padding: 18px;
  padding-left: 40px;
  height: auto;
  border-radius: 80px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #0a1a44;
}
.form-design .custom_checkbox .form-check-input {
  width: 24px;
  height: 24px;
  border: 1px solid #747474;
  margin-left: -22px;
  margin-top: 0 !important;
  background: #0a1a44;
}
.form-design .custom_checkbox .form-check-input:checked {
  background: #146ef5;
  box-shadow: 0 0 0 2px #146ef5 !important;
  width: 22px;
  height: 22px;
  border: 3px solid #0a1a44;
}
.pricing_range {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 10px;
  background: #101436;
  outline: none;
  opacity: 1;
  -webkit-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;
  max-width: calc(100% - 22px);
  margin: auto;
}

.form-design label:not(.form-floating label) {
  margin-bottom: 5px;
}

.form-design select.form-control {
  background: #0a1a44 url(./public/img/selecticon.png) no-repeat right 20px
    center;
}

.form-design button {
  color: #fff;
  font-family: "Samsung Sharp Sans Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 160px;
  background: #146ef5;
  display: inline-block;
  padding: 13px 40px;
  border: 1px solid #146ef5;
  width: 100%;
  max-width: 320px;
  transition: all 0.5s ease-in-out;
}

.form-design button:hover {
  background: rgba(20, 110, 245, 0.4); /* 50% transparent background */
  color: #146ef5;
}

.custom_switch {
  min-height: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #146ef5;
  border-color: #146ef5;
}

.custom_switch input[type="checkbox"] {
  cursor: pointer;
  height: 16px;
  background-repeat: no-repeat;
  background-image: url(./public/img/off_dot.png) !important;
  background-color: #4f090b;
  border-radius: 40px;
  border: 1px solid #fff;
  width: 32px;
  flex: 0 0 32px;
  margin-right: 4px;
}

.custom_switch label {
  margin-left: 2px;
  color: #ff3f45;
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 19.5px */
}
.custom_switch input[type="checkbox"]:checked ~ label {
  color: #4caf50;
}
.custom_switch input[type="checkbox"]:checked {
  background-color: #1f4c21;
  background-image: url(./public/img/checked_dot.png) !important;
}
.custom_switch label {
  margin-left: 2px;
  color: #ff3f45;
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 19.5px */
}
.custom_switch input[type="checkbox"]:checked ~ label {
  color: #4caf50;
}
.custom_switch {
  min-height: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.expendable_search {
  position: relative;
}

.search_btn {
  position: absolute;
  background: transparent;
  border: none;
  padding: 0;
}

.expendable_search input[type="search"] {
  color: #146ef5;
  font-family: "Samsung Sharp Sans Medium";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  border: none;
  width: 100px;
  border-bottom: 1px solid #146ef5;
  padding-left: 25px;
  height: 27px;
  transition: 0.3s linear;
}

.expendable_search input[type="search"]::placeholder {
  color: #146ef5;
}

.expendable_search input[type="search"]:focus {
  width: 200px;
}

.form-control:focus {
  color: #fff !important;
}

.show_password_fields,
.update_user_info {
  width: auto !important;
  margin: 15px 5px !important;
}

.vertical-space-20px {
  margin-top: 20px;
}
.mainCursor {
  cursor: pointer;
}
.display-none {
  display: none;
}

/* CSS for the blue blur background */
.blur-background-top-left,
.blur-background-top-right,
.blur-background-bottom-left,
.blur-background-bottom-right {
  position: relative;
}
.blur-background-top-left::before,
.blur-background-top-right::after,
.blur-background-bottom-left::before,
.blur-background-bottom-right::after {
  content: "";
  position: absolute;
  width: 281.182px;
  height: 281.182px;
  border-radius: 281.182px;
  background: #146ef5;
  filter: blur(90px);
  flex-shrink: 0;
  z-index: -10; /* Ensure it appears behind the image */
}
.blur-background-top-left::before {
  top: 0;
  left: 0;
}
.blur-background-top-right::after {
  top: 0;
  right: 0;
}
.blur-background-bottom-left::before {
  bottom: 0;
  left: 0;
}
.blur-background-bottom-right::after {
  bottom: 0;
  right: 0;
}
.smaller-blur::before,
.smaller-blur::after {
  width: 200px;
  height: 200px;
  border-radius: 200px;
}

@media only screen and (max-width: 1199px) {
  a,
  .link-btn {
    font-size: 15px;
  }

  .banner-heading h1,
  .banner-heading h2 {
    font-size: 36px;
    line-height: 42px;
  }
}

@media only screen and (max-width: 991px) {
  .heading h1,
  .heading h2 {
    font-size: 36px;
    line-height: 45px;
  }

  .banner-heading {
    margin-bottom: 25px;
  }

  .banner-heading h1,
  .banner-heading h2 {
    font-size: 34px;
    line-height: 42px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .heading h1,
  .heading h2 {
    font-size: 34px;
    line-height: 42px;
  }

  p {
    font-size: 16px;
  }

  .link-btn {
    padding: 10px 30px;
  }

  .banner-heading h1,
  .banner-heading h2 {
    font-size: 34px;
    line-height: 42px;
  }

  .banner-heading p {
    font-family: "Samsung Sharp Sans Regular";
  }

  .banner-heading a {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .heading h1,
  .heading h2 {
    font-size: 34px;
    line-height: 42px;
  }

  .heading h1 {
    font-size: 34px;
    line-height: 42px;
  }

  .expendable_search {
    width: 100%;
    margin-top: 20px;
  }

  .expendable_search input[type="search"] {
    width: 100% !important;
  }
}

@font-face {
  font-family: "Samsung Sharp Sans Regular";
  font-style: normal;
  font-weight: normal;
  font-weight: 400;
  src: local("Samsung Sharp Sans Regular"),
    url("../public/samsungsharpsans.woff") format("woff");
}

@font-face {
  font-family: "Samsung Sharp Sans Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Samsung Sharp Sans Medium"),
    url("../public/samsungsharpsans-medium.woff") format("woff");
}

@font-face {
  font-family: "Samsung Sharp Sans Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Samsung Sharp Sans Bold"),
    url("../public/samsungsharpsans-bold.woff") format("woff");
}
