.boxLoader_css {
  display: flex;
  justify-content: center;
  height: 89%;
  align-items: center;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure it's above other content */
}

/* Additional CSS to center the spinner */
/* .spinner-border {
  width: 4rem; 
  height: 4rem; 
} */

.inbox-loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Adjust as needed */
}