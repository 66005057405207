.panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.panel-overlay.open {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.pull-out-panel {
  position: fixed;
  top: 0;
  right: 0;
  min-width: 860px;
  height: 100%;
  background-color: #000212;
  color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  overflow: auto;
  z-index: 999;
  transform: translateX(100%);
  /* Improve animation smoothness */
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  pointer-events: none;
}

.pull-out-panel .close-button {
  position: absolute;
  top: 15px;
  left: 15px; /* Top left corner */
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.pull-out-panel .panel-content {
  margin-top: 60px;
  padding: 20px;
}

/* Optional: Slide-in animation */
.pull-out-panel.open {
  transform: translateX(0);
  pointer-events: auto;
}

/* Quick Add Styles */
.quick-add-container {
  text-align: left;
  width: 860px;
}

@media (max-width: 860px) { /* 860px is the min width of the panel on larger screens. For smaller screens, make sure it takes up 100%, without expanding beyond the viewport (though it will expand beyond if it has sub-components that necessarily take up more space, which is acceptable */
  .pull-out-panel {
    min-width: 100%;
    width: 100%;
  }
}

/* --------------- Quick Add --------------- */

.quick-add-title {
  font-size: 24px;
  margin-bottom: 16px;
  color: #fff;
}

.quick-add-description {
  color: #a0a0a0;
  margin-bottom: 24px;
  font-size: 16px;
}

.quick-add-label {
  display: block;
  margin-bottom: 2px;
  margin-left: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.quick-add-input {
  width: calc(100% - 32px);
  padding: 12px 16px;
  margin-bottom: 24px; /* Increased margin to accommodate label */
  background-color: #1a1a2e;
  border: 1px solid #2d2d3d;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
}

.quick-add-textarea {
  width: calc(100% - 32px);
  padding: 16px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #0a1a44 !important;
  border-radius: 20px;
  color: #fff;
  font-size: 15px;
  resize: vertical;
  min-height: 300px;
  font-family: "Samsung Sharp Sans Medium";
}

.quick-add-textarea::placeholder {
  color: #888;
}

.quick-add-input:focus,
.quick-add-textarea:focus {
  outline: none;
  border-color: #3a3a4c;
}

.quick-add-button {
  padding: 12px 20px;
  border-radius: 15px;
  margin: 0; /* Remove the conflicting margin */
  width: 300px; /* Fixed width for both buttons */
}

.quick-add-buttons-container {
  display: flex;
  justify-content: center;
  gap: 40px; /* Space between buttons */
  margin-top: 24px;
  width: 100%;
}

.view-previous-button {
  margin: 12px auto 0;
  width: fit-content;
  display: block;
  color: #0078f0;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.view-previous-button:hover {
  text-decoration: underline;
}

.btn-unselected {
  border: 1px solid #0078f0;
  color: #0078f0;
  background-color: transparent;
}
