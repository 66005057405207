.plan-info-box {
  border: 1px solid #146ef5;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
}

.plan-info-box h6 {
  color: #fff;
  margin: 0;
}

.plan-info-box h6 strong {
  background: linear-gradient(180deg, #6CA7FF 1.92%, #146EF5 119.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.plan-info-box h3 {
  font-family: 'Samsung Sharp Sans Bold';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(180deg, #6CA7FF 1.92%, #146EF5 119.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
}