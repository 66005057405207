@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

.account-main {
  padding: 74px 0;
}

.banner-heading {
  max-width: 753px;
  margin: 0 auto 45px;
  text-align: center;
}

.dashboard-container {
  height: 100%;
  border-radius: 30px;
  border: 1px solid #2c389e;
  background: #000212;
  box-shadow: 2px 0px 20px 0px rgba(255, 255, 255, 0.5); /* x-offset, y-offset, blur-radius, spread-radius, color */
}

.dashboard-container .account_heading {
  padding: 22px 40px;
  border-bottom: 1px solid #2c389e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dashboard-container > div:not(:last-child) {
  border-bottom: 1px solid #2c389e;
  padding: 22px 40px;
}

.dashboard-container .account_heading h3 {
  color: #146ef5;
  text-align: left;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.dashboard-container .account_heading h4 {
  color: #DDD;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.dashboard-container .account-content {
  padding: 30px 40px;
}

.dashboard-container .account-content .account-box {
  border-radius: 15px;
  background: #020d29;
  padding: 20px;
  height: 100%;
}

.dashboard-container .account-content .account-box svg {
  width: 34px;
  height: 34px;
  background: rgba(20, 110, 245, 0.2);
  border-radius: 7px;
  padding: 8px;
  margin-bottom: 12px;
}

.dashboard-container .account-content .account-box h4 {
  color: #146ef5;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.dashboard-container .account-content .account-box p {
  color: #cdcdcd;
  font-family: "Samsung Sharp Sans Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.dashboard-container .account-content > div:not(:last-child) {
  margin-bottom: 10px;
}

.dashboard-container .account-content .property-status {
  border-radius: 15px;
  border: 1px solid #2c389e;
  background: #000212;
}

.dashboard-container .account-content .property-status h3 {
  color: #cdcdcd;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px 20px 18px;
  border-bottom: 1px solid #2c389e;
}

.dashboard-container .account-content .property-status li {
  padding: 20px;
}

.dashboard-container .account-content .property-status li button {
  color: #fff;
  font-family: "Samsung Sharp Sans Medium";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 160px;
  background: #146ef5;
  display: inline-block;
  padding: 13px 40px;
  border: 1px solid #146ef5;
  padding: 11px 24px;
  margin: 12px;
  font-size: 15px;
}

.dashboard-container .account-content .get-started {
  border-radius: 15px;
  border: 1px solid #2c389e;
  background: #000426;
  padding: 53px 16px;
  text-align: center;
}

.dashboard-container .account-content .get-started img {
  margin: 0 0 20px;
}

.dashboard-container .account-content .get-started p {
  color: #ccc;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 20px;
}

.dashboard-container .account-content .get-started a {
  color: #146ef5;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}

.property_status {
  border-radius: 15px;
  border: 1px solid #2c389e;
  background: #000212;
}
.property_status h3 {
  color: #cdcdcd;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px 20px 18px;
  border-bottom: 1px solid #2c389e;
}
.property_list_cnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.property_cntleft {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.property_list > ul > li {
  padding: 20px;
}
.property_cntleft img {
  width: 54px;
  height: 54px;
  border-radius: 7.2px;
  object-fit: cover;
  margin-right: 15px;
}
.property_cntleft h4 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}
.property_cntleft p {
  color: #ccc;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
}
.action-items-table th,
.action-items-table tr,
.action-items-table td {
  border: 0px !important;
  font-size: 16px;
  font-weight: 500 !important;
}
.action-items-table thead th {
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  color: #b5b2b2;
}
.action-items-table td {
  font-size: 13px !important;
  color: #cdcdcd;
}
.search {
  border: 1px solid #2c389e !important;
  color: #555454;
  padding: 2px;
}
.p-dropdown-panel {
  background: #fff;
  padding: 5px;
  /* height: 300px; */
}
.action-item-tooltip {
  max-width: 500px;
  word-wrap: break-word;
  text-align: center;
}

.p-dropdown-panel .p-dropdown-items li {
  padding: 5px 0px;
}
.p-dropdown-filter-container svg {
  position: absolute;
  right: 5px;
}
svg.p-icon.p-dropdown-trigger-icon.p-clickable {
  display: none;
}
.icon-style {
  padding: 6px 9px 6px 9px;
  background: rgba(20, 110, 245, 0.2);
  color: rgb(20, 110, 245);
  border-radius: 8px;
  font-size: large;
}
@media only screen and (max-width: 1199px) {
  .dashboard-container {
    border-radius: 10px;
  }

  .dashboard-container > div:not(:last-child) {
    padding: 18px 20px;
  }

  .dashboard-container .account_heading h3 {
    font-size: 18px;
  }

  .dashboard-container .account-content {
    padding: 25px 20px;
  }

  .dashboard-container .account-content .account-box {
    padding: 15px;
    border-radius: 10px;
    height: 100%;
  }

  .dashboard-container .account-content .row > div:not(:last-child) .account-box {
    height: auto;
    margin-bottom: 20px;
  }

  .dashboard-container .account-content .property-status li button {
    font-size: 15px;
  }

  .dashboard-container .account-content .get-started {
    padding: 15px;
  }

  .account-main {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 991px) {
  .dashboard-container .account-content .get-started {
    margin-top: 20px;
  }

  .banner-heading {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .dashboard-container > div:not(:last-child),
  .dashboard-container .account-content {
    padding: 15px;
  }

  .dashboard-container .account_heading h3 {
    font-size: 17px;
  }

  .dashboard-container .account-content .row > div:not(:last-child) .account-box {
    margin-bottom: 15px;
  }

  .dashboard-container .account-content .account-box h4 {
    font-size: 20px;
  }

  .dashboard-container .account-content .property-status li {
    padding: 15px;
  }

  .dashboard-container .account-content .property-status li button {
    padding: 10px 30px;
  }
  .property_list_cnt {
    flex-wrap: wrap;
  }
  .property_list_cnt > div {
    width: 100%;
    text-align: left;
    justify-content: left;
  }
  .property_cntleft {
    margin-bottom: 15px;
  }
}
