.action-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}

.action-heading h3 {
    font-size: 25px;
    color: #fff;
}

.action-select {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;
  width: 80%;
}

.action-select select {
  border: 1px solid #146ef5;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 16 16%22%3E%3Cpath fill=%22none%22 stroke=%22%23ffffff%22 stroke-linecap=%22round%22 stroke-linejoin=%22round%22 stroke-width=%222%22 d=%22m2 5 6 6 6-6%22/%3E%3C/svg%3E");
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust position if needed */
}

.action-select select:focus {
  outline: 0;
  box-shadow: none;
}
.pro-icon1 {
  position: absolute;
  top: 50%;
  color: #fff;
  right: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  padding-left:10px;
    padding-right:10px;
}
.action-select .item-select {
    width: 23%;
    
}

.action-items-page .table-responsive {
    min-height: 500px;
}

@media screen and (max-width:991px)
{
    .action-heading h3 {
        font-size: 20px;
        
    }
}
@media screen and (max-width:767px)
{
    .action-heading h3 {
        font-size: 18px;
        
    }
    .action-items {
       flex-wrap:wrap;
       
       }
       .action-select{
        justify-content: start;
        padding-top:20px;
        flex-wrap:wrap;

       }
}
@media screen and (max-width:575px)
{
    .action-select .item-select {
        width: 100%;
    }
}