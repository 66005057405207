@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

.introduction {
    padding-top: 50px;
    padding-bottom: 50px;
}

.introduction-heading h1, .introduction-heading h2 {
  color: #fff;
  text-align: center;
  font-family: "Samsung Sharp Sans Bold";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 16px;
}

.introduction-heading h1 strong, .introduction-heading h2 strong {
  font-weight: 700;
  color: #146ef5;
}

.introduction .introduction-content {
    text-align: center;
    margin: 0 auto;
    width: 60%;
}

.introduction .introduction-content p {
    color: #CCC;
    font-family: "DM Sans", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.36px;
    margin-bottom: 25px;
}

.works-content {}

@media only screen and (max-width: 1199px) {
    a,
    .link-btn {
      font-size: 15px;
    }
  
    .introduction-heading h1, .introduction-heading h2 {
      font-size: 36px;
      line-height: 42px;
    }
  }
  
  @media only screen and (max-width: 991px) {
  
    .introduction-heading {
      margin-bottom: 25px;
    }
  
    .introduction-heading h1, .introduction-heading h2 {
      font-size: 34px;
      line-height: 42px;
      margin-bottom: 10px;
    }
  }
  
  @media only screen and (max-width: 767px) {
  
    .introduction-heading h1,
    .introduction-heading h2 {
      font-size: 34px;
      line-height: 42px;
    }
  }
  