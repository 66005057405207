.features .heading-box {
    padding-bottom: 40px;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    margin-bottom: 0;
}

.features .heading-box h2 {
    color: #FFF;
    text-align: center;
    font-family: 'Samsung Sharp Sans Bold';
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 13px;
}

.features .heading-box p {
    color: #FFF;
    text-align: center;
    font-family: 'Samsung Sharp Sans Medium';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.features .features-list tr {
    border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.features .features-list table {
    width: 100%;
}

.features .features-list table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: transparent;
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px transparent;
}

.features .features-list table>:not(:last-child)>:last-child>* {
    border-bottom-color: currentColor;
}

.features .features-list table th, .features .features-list table td {
    padding: 25px 0;
}

.features .features-list table th h3 {
    color: #FFF;
    font-family: 'Samsung Sharp Sans Bold';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 0 9px;
    text-align: center;
}

.features .features-list table th p {
    color: #808080;
    font-family: 'Samsung Sharp Sans Regular';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}

.features .features-list table td {
    text-align: center; /* center the check/x image */
}

.features .features-list table td h5 {
    margin: 0;
    color: #B3B3B3;
    font-family: 'Samsung Sharp Sans Bold';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
}

.features .features-list table td:not(:first-child) {
    padding-left: 10px;
    padding-right: 10px;
}

.features .features-list table td img {
    border: 0;
    max-width: 100%;
}

.features .features-list table tbody tr:last-child {
    border-color: transparent;
}

.features .features-list table tbody p {
    color: #FFF;
    font-size: 16px;
    text-align: center;
}

.features .features-list .unlimited-text {
    font-size: 14px;
}

@media only screen and (max-width: 991px) {
    .features .features-list table th, .features .features-list table td {
        padding: 15px 0;
    }

    .features .features-list table th h3 {
        font-size: 16px;
        margin: 0 0 5px;
    }

    .features .features-list table td h5 {
        font-size: 15px;
    }

    .features .features-list table tbody p {
        font-size: 15px;
    }

    .features .features-list .unlimited-text {
        font-size: 12px;
    }

    .features .features-list table td img {
        max-width: 18px;
    }
}

@media only screen and (max-width: 767px) {
    .features .heading-box {
        padding-bottom: 20px;
    }

    .features .heading-box h2 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 7px;
    }

    .features .heading-box p {
        font-size: 16px;
    }
}