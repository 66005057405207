.preferences_tab_grid.text-white {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 15px;
  margin-top: 40px;
}
.setting_tab_title h6 svg {
  width: 15px;
  height: 15px;
}
.setting_tab_title .setting-tab-link:hover,
.setting_tab_title .setting-tab-link.active {
  background: #0b2872c4;
  border-radius: 10px;
}
.setting_tab_data {
  padding: 30px 50px;
}
.setting_tab_data .form-control {
  padding: 8px 15px !important;
}
.setting_tab_data .bg_theme_btn {
  padding: 10px 40px !important;
  font-size: 16px !important;
  margin-top: 0 !important;
}
.setting_tab_data_inner {
  padding-right: 10px;
  padding-bottom: 20px;
  overflow-x: hidden;
}
.conversation-settings-inbox .setting_tab_data .setting-textarea {
  height: 70px !important;
}
@media screen and (max-width: 768px) {
  .preferences_tab_grid.text-white {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 575px) {
  .setting_tab_data .bg_theme_btn {
    padding: 10px 30px !important;
    font-size: 16px !important;
    margin-top: 0 !important;
    display: block;
    width: 100%;
  }
}
