.calendar-week-field h4 {
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.calendar-week-field {
  border: 2px solid #13317b;
  padding: 10px;
  border-radius: 10px;
}

/* {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    padding: 20px;
} */
.calendar-schedule-data .custom-row {
  border: 2px solid #2852ba;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  max-height: 90px;
  min-height: 90px;
  height: 100%;
  overflow-y: auto;
  margin: 2px;
}

.calendar-schedule-data.data-head {
  border: 0px;
  /* background: green; */
  color: #fff;
  display: flex;
  font-size: 14px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
}

.calendar-schedule-data.data-head h5 {
  font-size: 14px;
}

.calendar-schedule-time {
  border-bottom: 1px solid #13317b;
  padding: 0px 0 0 5px;
  gap: 10px;
  align-items: center;
}

.calendar-schedule-time .custom-row p {
  font-size: 12px;
  color: #fff;
  display: flex;
  gap: 6px;
  justify-content: space-between;
  padding: 0 5px;
}

.calendar-schedule-time .custom-row div {
  display: grid;
  grid-template-columns: auto 20px;
  border-bottom: 1px solid #0d6efd;
  align-items: center;
}

span.calendar-schedule-button svg {
  width: 12px;
  height: 12px;
  color: #0d6efd;
}

span.calendar-schedule-button {
  display: inline-block;
  text-align: center;
}

.calendar-schedule-data .custom-row::-webkit-scrollbar {
  width: 2px;
}

.future-status {
  background: #0d6efd;
}
.current-status {
  background: #30ff30;
}
.inquery-status {
  background: #fff;
}
.placeholder-status {
  opacity: 0;
}
.calender-table-status {
  width: 13px;
  height: 13px;
  display: inline-block;
  border-radius: 20px;
}
.calendar-timeline {
  display: grid;
  grid-template-columns: auto 1fr auto auto;
  gap: 75px;
}
.calendar-table-list-data {
  position: relative;
  padding: 15px 15px 0px 15px;
}
.calendar-table-data-child {
  overflow-y: auto;
  height: 90px;
  padding-right: 10px;
}
.calendar-table-list-data::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 1px;
  background: #6c757d;
  top: 0;
  left: 0;
}

.calendar-table-data-child::-webkit-scrollbar {
  width: 3px;
}

.off-all-day {
  display: none;
}

@media screen and (max-width:1440px) {
  .calendar-timeline {
    gap: 50px;
}
  
}
@media screen and (max-width:1200px) {
  .calendar-timeline {
    gap: 20px;
}
  
}
@media screen and (max-width:991px) {
  .border-remove{
    border: 0px !important;
  }
  .calendar-table-list-data::before {
    display: none;
  }
  .calendar-table-data-child {
    padding: 10px;
}
}
@media screen and (max-width:575px){
  .calendar-table-list-data .fs-14{
    font-size: 12px !important;
  }
}