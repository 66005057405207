.unlock-properties-modal hr {
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #e5e5e5;
}

.unlock-properties-modal p {
  color: white;
  margin-bottom: 20px;
  text-align: center;
}

.unlock-properties-modal button {
  border-radius: 25px;
  margin: 0 auto;
  display: block;
  padding: 10px 25px;
}