/* In this file, always include the parent class name (.settings-integrations or .connect-turno-button) to avoid conflicts with other CSS files */
/* FYI page background is: #000212 */

.settings-integrations {
  padding: 20px;
}

.settings-integrations .grey-text {
  color: #777;
}


.connect-turno-button .connect-button {
  border: 2px solid blue;
  color: white;
  background-color: transparent;
  border-radius: 20px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.connect-turno-button .connect-button:hover {
  background-color: blue; /* Blue background on hover */
}

.settings-integrations .tiles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: flex-start; /* changed from center for left justified buttons */
  width: 100%;
  margin-top: 30px;
}

.settings-integrations .partner-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 400px; /* Don't grow, don't shrink, stay at 400px */
  height: auto;
  min-height: 300px;
  border: 2px solid #045ce9;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  background-color: #DDD;
  box-shadow: 0 0 20px 5px rgba(0, 0, 200, 0.5); /* parameters: x-offset, y-offset, blur-radius, spread-radius, color */
  transition: transform 0.3s, box-shadow 0.3s;
}

.settings-integrations .partner-tile:hover {
  transform: translateY(-10px);
  box-shadow: 0 0 20px 8px rgba(0, 0, 200, 0.8); /* parameters: x-offset, y-offset, blur-radius, spread-radius, color */
}

.settings-integrations .partner-logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.settings-integrations .partner-tile p {
  font-size: 16px;
  color: #333; /* Dark text color */
  text-align: center;
}

.settings-integrations .integrations-tabs {
  display: flex;
  justify-content: flex-start; /* Left-align the tabs */
  margin-top: 20px; /* Reduced from 40px since we now have a title */
}

.settings-integrations .tab-button {
  background-color: rgb(50, 50, 50);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #6b8aff;  /* Light blue text color */
}

.settings-integrations .tab-button.active {
  background-color: rgba(0, 0, 255, 0.2);
  border-color: #6b8aff;
  color: #89a4ff;  /* Lighter blue for active state */
  box-shadow: 0 2px 8px rgba(107, 138, 255, 0.3);
}

.settings-integrations .tab-button:hover {
  background-color: rgba(107, 138, 255, 0.15);  /* Slightly blue tinted background */
  border-color: rgba(107, 138, 255, 0.3);
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(107, 138, 255, 0.2);
}

.settings-integrations .connected-integrations-section {
  margin-top: 80px;
}

.settings-integrations .connected-title {
  color: white;
  font-size: 20px;
  margin-bottom: 20px;
}

.settings-integrations .no-integrations-message {
  color: #AAA;
  font-size: 16px;
  text-align: center;
  padding: 30px;
  border: 1px dashed #555;
  border-radius: 8px;
  margin-top: 20px;
}