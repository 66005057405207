.routes .row {
    width: 100%;
}

.inbox-content-container {
  position: relative;
}

/* Mobile Styles */
@media (max-width: 991.98px) {
  .left-bar,
  .milde-section,
  .right-section {
    width: 100%;
  }

  .mobile-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .mobile-nav .btn-link {
    color: #146ef5;
    font-size: 16px;
  }
}