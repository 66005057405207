.graph-banner {
  position: relative;
  margin-top: 100px;
  margin-bottom: 200px;
}
.graph-banner .image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.graph-banner .image-container:nth-child(1) {
  position: absolute;
  left: 160px;
  max-width: 248px;
  max-height: 195px;
  width: 100%;
  height: 100%;
  top: -40px;
  z-index: 1;
}
.graph-banner .image-container:nth-child(2) {
  max-width: 621px;
  max-height: 422px;
  margin: auto;
  width: 100%;
  height: 100%;
}
.graph-banner .image-container:nth-child(3) {
  position: absolute;
  right: 20px;
  max-width: 481px;
  max-height: 224px;
  width: 100%;
  height: 100%;
  top: 100px;
  z-index: 1;
}
.graph-banner .image-container:nth-child(4) {
  position: absolute;
  left: 190px;
  max-width: 304px;
  max-height: 240px;
  width: 100%;
  height: 100%;
  top: 212px;
  z-index: 1;
}
.graph-banner .image-container:nth-child(5) {
  position: absolute;
  right: 420px;
  max-width: 328px;
  max-height: 162px;
  width: 100%;
  height: 100%;
  top: 357px;
  z-index: 1;
}
.image-container.image-five img,
.image-container.image-third img {
  border: 1px solid #146ef5;
}
.graph-banner .image-container img {
  width: 100%;
  height: 100%;
  border-radius: 6px !important;
  object-fit: contain;
  opacity: 0.1;
  transition: filter 1s ease, opacity 1s ease;
}

.image-container.active img {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .graph-banner .image-container:nth-child(1) {
    left: 0;
  }
  .graph-banner .image-container:nth-child(3) {
    right: 0;
  }
  .graph-banner .image-container:nth-child(4) {
    left: 0;
  }
  .graph-banner .image-container:nth-child(5) {
    right: 27.5%;
  }
}
@media screen and (max-width: 991px) {
  /* .graph-banner {
    position: relative;
    margin-top: 60px;
    margin-bottom: 0px;
    display: flex;
    gap: 20px;
    flex-direction: column;
  } */
  /* .graph-banner .image-container {
    position: inherit !important;
    max-width: 100% !important;
    max-height: 100% !important;
    left: inherit !important;
    right: inherit !important;
    top: inherit !important;
    bottom: inherit !important;
  } */
}

@media screen and (max-width: 767px) {
  .graph-banner .image-container:nth-child(1) {
    width: 100%;
    height: 100%;
    top: -91px;
    z-index: 1;
  }
  .graph-banner .image-container:nth-child(3) {
    right: 0px;
    width: 56%;
    top: -58px;
    z-index: 1;
  }
  .graph-banner .image-container:nth-child(4) {
    max-width: 50%;
    top: 168px;
  }
  .graph-banner .image-container:nth-child(5) {
    max-width: 50%;
    top: 248px;
    right: 0px !important;
  }
}

@media screen and (max-width: 575px) {
  .graph-banner .image-container:nth-child(1) {
    max-width: 40%;
  }
  .graph-banner .image-container:nth-child(5) {
    max-width: 49%;
    top: 159px;
  }
  .graph-banner .image-container:nth-child(4) {
    max-width: 44%;
    top: 122px;
  }
}

@media screen and (max-width: 480px) {
  .graph-banner {
    position: relative;
    margin-top: 90px;
    margin-bottom: 110px;
  }
  .image-container {
    height: auto !important;
  }
  .graph-banner .image-container:nth-child(1) {
    /* top left img */
    top: -70px;
    left: 5px;
  }
  .graph-banner .image-container:nth-child(3) {
    /* top right img */
    top: -64px;
    right: -10px;
  }
  .graph-banner .image-container:nth-child(4) {
    /* bottom left img */
    max-width: 44%;
    top: 242px;
  }
  .graph-banner .image-container:nth-child(5) {
    /* bottom right img */
    max-width: 49%;
    top: 222px;
    right: 0px !important;
  }
}
/*
@media screen and (max-width: 380px) {
  .graph-banner .image-container:nth-child(1) {
    top: -21px;
  }
  .graph-banner .image-container:nth-child(3) {
    top: 20px;
  }
  .graph-banner .image-container:nth-child(5) {
    top: 136px;
  }
}
*/
