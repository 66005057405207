/* .form-switch .form-check-input {
    background-image: url("../../../public/img/wrong_check2.png");
    border: 1px solid #fff;
    background-color: #4F090B;
    background-size: 40% 78%;
    background-position-x: 4px;
} */
.property_listing_detail {
  display: flex;
  gap: 10px;
}
.img-with-title {
  width: 140px;
  height: 110px;
  margin-right: 15px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
}
.img-with-title img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-with-title span {
  position: absolute;
  top: 7px;
  left: 7px;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.55px;
  text-transform: uppercase;
  border-radius: 6px;
  background: #146ef5;
  display: inline-block;
  padding: 7px 8px;
}
.property-detail h4 {
  font-size: 18px;
  margin-bottom: 7px;
  color: #fff;
}
/*
.property-edit-btn {
  border-radius: 160px;
  border: 1px solid #1a5fb4 !important;
  margin-right: 0 !important;
  color: #fff !important;
  background: #1a5fb4 !important;
  padding: 4px 20px;
  display: inline-block;
  box-shadow: none !important;
  transition: all 0.3s ease;
}
.property-edit-btn:hover {
  background: #0d4899 !important;
  border-color: #0d4899 !important;
  transform: translateY(-1px);
}
*/
.property-dropdown {
  margin-left: auto; /* Aligns the dropdown to the far right */
  position: absolute; /* Change to absolute */
  top: 0;
  right: 0;
}
.property-dropdown-old .btn {
  border-radius: 160px;
  border: 1px solid #146ef5 !important;
  margin-right: 12px;
  color: #fff;
  background: #146ef5 !important;
  padding: 4px 20px;
  display: inline-block;
  box-shadow: none !important;
}
/* This is the little down arrow after the vertical "...". Remove this block to show the arrow; keep it to hide the arrow */
/*
.property-dropdown .dropdown-toggle::after {
  display: none;
}
*/
.test-property-btn, .property-edit-btn {
  color: #146ef5;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 40px;
  background: rgba(20, 110, 245, 0.2);
  display: inline-block;
  padding: 9px 20px;
  margin-top: 0;  /* Remove top margin since we're using gap */
  box-shadow: none !important;
  border: none;
}
.property-dropdown .dropdown-menu.show {
  border-radius: 30px;
  background: #020d29;
  width: 262px;
  padding: 30px;
}
.property-dropdown .dropdown-menu.show a {
  color: #fff !important;
  background: none !important;
  padding: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.45px;
  margin-bottom: 15px;
}
.property-dropdown .dropdown-menu.show a:hover {
  color: #146ef5 !important;
}
.progressBar {
  height: 50px;
  width: 50px;
}

.dropdown-toggle-vertical {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  color: #fff; /* White color for the icon */
}

.dropdown-toggle-vertical:hover {
  background: none !important;
}

.property_lisiting {
  position: relative; /* Add this */
}

.property_listing_item {
  flex: 1; /* Add this */
}

.property_listing_btn {
  display: flex;
  flex-direction: column;  /* Stack buttons vertically */
  align-items: flex-end;   /* Right align buttons */
  gap: 10px;              /* Space between buttons */
  margin-right: 40px;     /* Space for the vertical dots */
}

@media screen and (max-width: 767px) {
  .property_lisiting {
    flex-wrap: wrap;
  }
  .property_listing_item {
    display: grid !important;
    grid-template-columns: 140px auto;
    gap: 20px !important;
    margin-bottom: 20px;
    width: 100%;
  }
  .property_listing_detail {
    justify-content: space-between;
  }
  .test-property-btn {
    margin-top: 0;
  }
  .property_listing_btn {
    flex-direction: row;    /* Revert to horizontal on mobile */
    align-items: center;    /* Center align on mobile */
    justify-content: flex-end; /* Right align on mobile */
    width: auto;            /* Remove 100% width */
    margin-right: 40px;     /* Keep space for dots menu */
  }
  .test-property-btn {
    font-size: 14px;
    font-weight: 500;
    padding: 9px 13px;
  }
}
@media screen and (max-width: 575px) {
  .chart-circle {
    display: none;
  }
}
