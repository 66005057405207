.addition_des .multiselect-container .search-wrapper{
    padding: 11px 20px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: #0a1a44 ;
}
.addition_des .multiselect-container .optionListContainer {
    background: #020d29
}
.modal_addition_des_button{
    max-width: 100%;
    padding: 13px 0px;
    display: flex;

}
.addition_des input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
}
.addition_des input[type="time"]{
    position: relative;
    appearance: none;
}
.addition_des input[type="time"]::before {
    content: "\F293";
    color: #fff; 
    position: absolute;
    right: 27px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; 
    font-family: 'bootstrap-icons';
}
.multiSelectContainer ul{
    max-height: 125px;
}
.addition_des .trigger-label {
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
    text-align: center;
}
.smartTemplateAddEdit .nameAndEnableSection {
    display: flex;
    align-items: start;
    width: 100%;
}
.smartTemplateAddEdit .enableSection {
    margin: 20px 0 20px 50px;
}
.smartTemplateAddEdit .propertySelectSection {
    margin-left: auto;
}
.smartTemplateAddEdit .ai-context-appropriate-section {
    margin-top: 20px;
    margin-bottom: 20px;
}

.smartTemplateAddEdit .triggersSection,
.smartTemplateAddEdit .targetsSection,
.smartTemplateAddEdit .conditionsSection,
.smartTemplateAddEdit .propertiesSection,
.smartTemplateAddEdit .followUp {
    margin: 40px 0 40px 0;
    border: 1px solid #0d6efd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 8px 0px rgba(255, 255, 255, 0.5); /* parameters: h-shadow v-shadow blur spread color */
}

.propertySelectSection .clickableLink {
    display: block;
    margin: 3px auto 0;
    font-size: 16px;
    text-align: center;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.template-status {
    margin-left: auto;
    font-size: 14px;
}

.template-status.enabled {
    color: green;
}

.template-status.not-enabled {
    color: red;
}

@media screen and (min-width:1024px){
    .modal_addition_des_button{
        max-width: 50%;
        padding: 13px 30px;
    }
}
@media screen and (max-width:425px){
    .modal_addition_des_button{
        display: block !important;
    }
}