.custom-select .css-13cymwt-control:focus, .css-t3ipsp-control {
    min-height: auto !important;
    height: auto !important;
}

.custom-select .css-13cymwt-control option{
    background:red;
}

button.select-btn {
    background: transparent;
    border-color: #fff;
    color: #fff;
    font-size: 12px;
    padding: 0;
    border-width: 0px 0px 1px 0px;
}

.custom-select .css-13cymwt-control {
    border-radius: 80px !important;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: #0a1a44 !important;
    color: #fff;
    font-family: "Samsung Sharp Sans Medium";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;   
    height: auto !important;
    overflow: hidden !important;
   
}

.cs-select-option select:focus{
    border-color:#2468ff;
    box-shadow: none;
}

button.save-btn {
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    width: 100%;
}

.custom-select .css-13cymwt-control .css-1jqq78o-placeholder{
    color:#fff;
}

.property_Custom_Select .css-12a83d4-MultiValueRemove svg{
    color: grey;
}

.property_Custom_Select .css-12a83d4-MultiValueRemove:hover svg{
    color: red;
}

.property_Custom_Select .css-1p3m7a8-multiValue{
    line-height: 150% !important;
}

.property_Custom_Select .css-t3ipsp-control{
    overflow: hidden !important;
}

.copy-to-properties-modal .time-zone-section {
    margin-bottom: 40px;
}

.copy-to-properties-modal .time-zone-section p {
    font-size: 15px;
    line-height: 1.2;
    width: 85%;
    text-align: center;
    margin: 0 auto;
    display: block;
}

.copy-to-properties-modal .time-zone-select {
    margin-bottom: 15px !important;
}

.copy-to-properties-modal .choose-properties {
    margin-bottom: 30px;
    margin-top: 20px;
}

.copy-to-properties-modal .select-all-container {
    display: flex;
    justify-content: center;
}