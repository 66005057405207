@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

.get_started {
  letter-spacing: 1px;
}

.get_started .welcome_steps {
  display: grid;
  gap: 40px;
  box-shadow: 0 4px 18px 0px rgba(255, 255, 255, 0.5);
}

.get_started .welcome_steps a {
  width: fit-content;
  margin-left: auto;
}

.get_started .step_odd {
  grid-template-columns: 500px 1fr;
  margin-bottom: 70px;
  background: #151530;
}

.get_started .step_even {
  grid-template-columns: 1fr 500px;
  margin-bottom: 70px;
  background: #151530;
}

.get_started h3 strong {
  font-weight: 700;
  color: #146ef5;
}

.get_started img {
  margin: auto 0;
  border: 1px solid #CCC;
  border-radius: 10px;
}

.get_started .get_started_featured {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 100px;
  row-gap: 80px;
}

.get_started .link-title {
  width: fit-content;
}

.get_started .link_card_box {
  min-height: 185px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s;
  box-shadow: 0 4px 18px 0px rgba(255, 255, 255, 0.5);
  background: #12122a;
}

.get_started .link_card_box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 18px 10px rgba(255, 255, 255, 0.5);
}

.get_started .more-coming-soon-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 185px;
}

.get_started .samsung-bold {
  font-family: "Samsung Sharp Sans Bold"
}

.get_started .color-light-grey {
  color: #CCC;
}
@media screen and (max-width: 991px) {
  .get_started .step_even  , .get_started .step_odd{
   margin-bottom: 30px;
}
.get_started .welcome_steps {
  display: flex;
  flex-direction: column;
}
.get_started .col-lg-12:nth-child(odd) .welcome_steps {
  flex-direction: column-reverse;
}
}
@media screen and (max-width: 768px) {
  .get_started .get_started_featured {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0px;
    row-gap: 20px;
  }

  .get_started .step_odd {
    grid-template-columns: 1fr;
  }

  .get_started .step_even {
    grid-template-columns: 1fr;
  }
}





/* --------- Redesign page style --------- */
.icon-con {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
}
.icon-con h2 {
  font-size: 150px;
  color: #fff;
  font-weight: 400;
}
.icon-right {
  display: flex;
  flex-direction: column;
}
.icon-right span {
  font-size: 20px;
  color: #fff;
  margin-bottom: 20px;
  text-align: right;
  font-family: "Samsung Sharp Sans Bold", sans-serif, Arial;
  font-weight: bold;
}
.icon-right img {
  border: 0px;
  width: 100%;
  max-width: 300px;
  height: 121px;
  object-fit: scale-down;
}
.new-get .link_card_box {
  padding: 30px 40px !important;
  border-width: 2px !important;
  border-radius: 40px !important;
  margin-bottom: 35px;
  max-width: 499px;
  background: #12122a;
}
.new-get .link_card_box:hover {
  -webkit-box-shadow: 0px 0px 64px -4px rgba(255,255,255,1);
  -moz-box-shadow: 0px 0px 64px -4px rgba(255,255,255,1);
  box-shadow: 0px 0px 64px -4px rgba(255,255,255,1) !important;
}
.new-get {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
.get-started-modal .modal-content .close {
  position: absolute;
  right: 20px;
  top: 10px;
  
}
.get-started-modal .modal-content {
  position: relative;
  z-index: 0;
}
.get-started-modal .modal-body h4 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
}
.get-started-modal .modal-body img {
  border-radius: 20px;
  border: 3px solid #146ef5;
  margin: 30px auto;
  width: 80%;
}
.get-started-modal .modal-footer {
  padding: 0px;
  border: 0px;
}
.get-started-modal .modal-content {
  border: 3px solid #146ef5;
  height: 90vh;
  overflow-y: scroll;
}

.get-started-modal .modal-content::-webkit-scrollbar {
  display: none;
}
.get-started-modal .modal-footer button {
  background: transparent;
  padding: 0px;
  margin: 0px;
  border: 0px;
}
.get-started-modal .modal-content .close i {
  font-size: 30px;
}
.get-started-modal .modal-content .close:hover {
  background: transparent;
}

@media (max-width: 1399.98px) {
  .icon-con {
    gap: 20px;
   
}
.icon-con h2 {
  font-size: 100px;
  
}
}
@media (max-width: 991px) {
.new-get .link_card_box {
 
  max-width: 100%;
}
}
@media (max-width: 575px) {
.icon-right img {
  max-width: 236px;
  
}
}