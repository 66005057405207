.left-bar-chat::-webkit-scrollbar{
width: 1px;
}
.left-bar .left-inner-tab {
    padding: 15px 10px;
    max-width: 530px;
    margin: 0 auto;
}
.left-inner-tab p{
    line-height: normal;
}
.left-bar .left-inner-tab img {
    width: 100%;
    border-radius: 0px !important;
    height: 74px;
    object-fit: cover;
}
.left-description p {
    font-size: 15px;
}
.left-description h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}
.short-des {
    font-size: 14px;
    line-height: normal;
}
.left-bar strong {
    font-family: 'Samsung Sharp Sans Bold';
}
.left-bar hr{
    margin: 0;
}
.left-description  p {
    font-size: 12px;
} 
.date {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 500;
}

/* ...existing code... */

.left-bar-chat {
    height: calc(100vh - 200px); /* Base height when filters are hidden */
    overflow-y: auto;
}

.left-bar-chat.filters-visible {
    height: calc(100vh - 305px); /* Original height when filters are visible */
}

/* ...existing code... */

.left-bar .row {
    width: 100%;
    overflow-x: hidden;
}
.message-filter .search-form {
    position: relative;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr auto;
    background: #212529;
    margin-left: auto;
    transition: all 2s ease-in-out;
}

.message-filter{    
    padding: 10px 20px;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
}
.messsage-search {
    width: 100%;
    justify-content: space-between;    
    align-items: center;
}
.messsage-search h2 {
    font-size: 22px;
    margin: 0px;
}
.filter-btns {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 10px;
}
.filter-btns select, .filter-btns span, .search-input input {
    background: transparent;
    border: 1px solid #146ef5;
    padding: 3px 10px !important;
    font-size: 14px;
    color: #fff;
    appearance: none;
    border-radius: 47px;
    max-width: 150px;
}
.custom-select .select-active{
    --bs-form-select-bg-img: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E);
    color: black;
}
.custom-select select{
    --bs-form-select-bg-img: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    color: #fff;
    background-image: var(--bs-form-select-bg-img),var(--bs-form-select-bg-icon,none);
    background-position: right 0.85rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    padding: 10px 35px 10px 10px;
    box-shadow: none;
    outline: none;
}

.filter-btns .active {
    background: #212529;
    border: 1px solid #146ef5;
    color: #ffffff;
}

.left-bar .search-input-wrapper {
    position: relative;
    width: 100%;
}

.left-bar .search-input {
  position: relative;
  display: flex;
  align-items: center;
}

.left-bar .search-input input {
  width: 100%;
  padding-right: 30px;
}

.left-bar .search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
}

.left-bar .search-icon:hover {
  color: #000;
}

.left-bar .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .left-bar .dropdown-item {
    padding: 5px;
    cursor: pointer;
  }
  
  .left-bar .dropdown-item:hover {
    background: #f0f0f0;
  }
  
  .left-bar .guest-name {
    font-weight: bold;
  }
  
  .left-bar .guest-property {
    font-size: 0.9em;
    color: #666;
  }


/* ==============responsive part start============== */
@media (max-width: 1599.98px) { 
    .left-description h2 {
        font-size: 15px;
        
    }

    .left-bar .left-inner-tab img {
       height: 55px;
        
    }
    .left-description  p  , .date{
        font-size: 12px;
    }
    .left-bar {
        padding-right: 0px;
    }
 }
 @media (max-width: 1199px) { 
    .left-bar .left-inner-tab img {
        height: 40px;
    }
    .left-bar .left-inner-tab img {
        height: 40px;
    }
    .left-bar .row.left-inner-tab .col-12 {
        width: 100%;
    }
    .left-bar .row.left-inner-tab {
        flex-direction: column;
        width: 100%;
    }
    .left-bar .left-inner-tab img {
        height: 40px;
        width: 40px;
        margin-bottom: 15px;
    }
 }

.message-filter .messsage-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messsage-search button {
  background: transparent;
  border: 1px solid #146ef5;
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  border-radius: 47px;
  cursor: pointer;
}

.messsage-search button.bg-light {
  background: #fff;
}

.messsage-search button.text-dark {
  color: #000;
}